<template>
    <BaseIcon class="gst-icon-browser-event" symbol-id="icons--browser_event" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconBrowserEvent',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";

    .gst-icon-browser-event .gst-svg-icon {
        fill: theme-color( 'primary' );
    }
</style>
