import { 
    setNotificationTermsVisibility as setNotificationTermsVisibilityUtils,
    getNotificationTermsVisibility as getNotificationTermsVisibilityUtils
} from '@tenant/utils/notificationTerms';

const defaultState = () => {
    const localStorageNotificationTerms = getNotificationTermsVisibilityUtils();

    if ( !localStorageNotificationTerms ) {
        setNotificationTermsVisibilityUtils( true );
    }

    return {
        visible: getNotificationTermsVisibilityUtils(),
        accepted: false
    };
};

const notificationTerms = {
    name: 'notificationTerms',
    namespaced: true,
    state: {
        ...defaultState()
    },
    getters: {
        getAccepted: state => state.accepted,
        getVisible: state => state.visible && !state.accepted
    },
    mutations: {
        setVisible( state, visibility ) {
            state.visible = visibility;
        },
        setAccepted( state ) {
            state.accepted = true;
        }
    },
    actions: {
        open( context ) {
            setNotificationTermsVisibilityUtils( true );
            context.commit( 'setVisible', true );
        },
        close( context ) {
            setNotificationTermsVisibilityUtils( false );
            context.commit( 'setVisible', false );
        },
        accept( context ) {
            context.commit( 'setAccepted' );
        }
    }
};

export default notificationTerms;