import Vue from 'vue';
import VueRouter from 'vue-router';
import { getFinalRoutes } from '@core/utils/routerUtils';

export default {
    create: ( { routes = [ ] } = { } ) => {
        Vue.use( VueRouter );

        return new VueRouter( {
            mode: 'history',
            base: '/',
            routes: getFinalRoutes( routes ),
            scrollBehavior ( to, from, savedPosition ) {
                if ( savedPosition ) {
                    return savedPosition;
                } else {
                    return to.hash ? { selector: to.hash } : { x: 0, y: 0 };
                }
            }
        } );
    }
};