export function normalizeUserLoyaltyWallet( data ) {
    /**
     * TODO: Improve and normalize all the response ex: if no firstName, set to null.
     * Change memberId to accountNumber. 
     * In first phase we leave it memberId to be able to test if response from BE is ok on all tenants
     */
    return {
        'referralId':           data.referralId,
        'balance':              data.pointsBalance,
        'id':                   data.id,
        'firstName':            data.firstName,
        'lastName':             data.lastName,
        'email':                data.email,
        'memberId':             data.accountNumber,
        'isRegistered':         data.isRegistered,
        'memberDetailsStatus':  data.memberDetailsStatus,
        'oauth2AccessToken': data.oauth2AccessToken ? {
            accessToken: data.oauth2AccessToken.accessToken,
            refreshToken: data.oauth2AccessToken.refreshToken,
        } : null,
        'memberStatus': data.status || null
    };
}