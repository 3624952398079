import apiServiceLeagueData from './apiServiceLeagueData';
import normalizeLeagueList from './response/normalizeLeagueList';

export default {
    get: async ( ) => {
        const data = normalizeLeagueList( apiServiceLeagueData );
        
        return {
            success: !!data,
            data: data
        };
    },
    one: async ( id ) => {
        const data = normalizeLeagueList( apiServiceLeagueData ).find( item => item.id === id );

        return {
            success: !!data,
            data: data
        };
    }
};