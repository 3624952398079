<template>
    <v-app>
        <component :is="layout">
            <router-view v-if="!loading" />
            <v-overlay :value="loadingOverlay || loading" color="white" opacity="0.8" z-index="5002">
                <DataLoading size="40" />
            </v-overlay>
        </component>
        <div id="modals_container"></div>
        <NotificationContainer />
        <BackToTop :visible-offset-bottom="50" />
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import debounce from 'lodash/debounce';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import DefaultLayout from '@core/shared/layouts/Default';
    import BlankLayout from '@core/shared/layouts/Blank';
    import BlankWithFooterVue from '@core/shared/layouts/BlankWithFooter';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import NotificationContainer from '@core/shared/components/notifications/NotificationContainer';
    import BackToTop from '@core/shared/components/enhancers/BackToTop';
    import viewportConstants from '@core/utils/constants/viewport';
    import { setMetaViewport as viewportUtilsSetMetaViewport } from '@core/utils/viewportUtils';
    import {
        normalizeInitialStateFromQuery as searchUtilsNormalizeInitialStateFromQuery
    } from '@core/utils/searchUtils';
    import { initDateFnsLocales } from '@core/utils/dateUtils';
    import entityConstants from '@core/utils/constants/entity';
    import authenticationConstants from '@core/utils/constants/authentication';

    /**
     * TODO HACK ionut
     * after we remove the pattern index.js we end up in a situatie where we have an css conflict order between chunks
     * for now we will "hide" that and resurect ticket
     * https://boatyardx.atlassian.net/browse/GL-1221
     */
    // eslint-disable-next-line no-unused-vars
    import ExpansionPanel from '@core/shared/components/misc/ExpansionPanel.vue';
    // eslint-disable-next-line no-unused-vars
    import HeaderVariant2 from '@core/shared/components/modals/layout/HeaderVariant2.vue';
    // eslint-disable-next-line no-unused-vars
    import EventHealthCheckWarningCardVariant1 from '@core/shared/components/notifications/EventHealthCheckWarningCardVariant1.vue';
    // eslint-disable-next-line no-unused-vars
    import EventHealthCheckWarningCardVariant2 from '@core/shared/components/notifications/EventHealthCheckWarningCardVariant2.vue';
    import AppAuthMixin from './AppAuthMixin';

    /* eslint-disable vue/component-definition-name-casing */
    Vue.component( 'default-layout', DefaultLayout );
    Vue.component( 'blank-layout', BlankLayout );
    Vue.component( 'blank-footer-layout', BlankWithFooterVue );
    /* eslint-enable vue/component-definition-name-casing */


    const STORE_SEARCH_STATE = 'searchState';
    const DEFAULT_LAYOUT = 'default';

    export default {
        name: 'App',
        components: {
            BackToTop,
            NotificationContainer,
            DataLoading
        },
        mixins: [
            AppAuthMixin
        ],
        data: function () {
            return {
                loading: false,
                isSplashScreenShown: true
            };
        },
        computed: {
            ...mapGetters( {
                userIsAuth:                         'user/isAuth',
                userEmail:                          'user/email',
                userHasCurrentLocationPosition:     'user/location/hasCurrentPosition',
                isSessionExpireIn:                  'user/isSessionExpireIn',
                userCurrentCityLocation:            'user/location/currentCityLocation',
                isCartSubmitting:                   'cart/isCartSubmitting',
            } ),
            ...mapState( {
                initialSearch: state => state[STORE_SEARCH_STATE].initial,
                loadingOverlay: state => state['appState'].loading,
                userFirstAccess: state => state.user.firstAccess
            } ),
            layout( ) {
                const layout = this.$route.meta.layout || DEFAULT_LAYOUT;

                if ( layout.toLocaleLowerCase( ) === 'blank' ) {
                    return 'blank-layout';
                }

                if ( layout.toLocaleLowerCase( ) === 'blank-footer' ) {
                    return 'blank-footer-layout';
                }

                return 'default-layout';
            }
        },
        methods: {
            ...mapActions( {
                clearCurrentSearchState:            `${STORE_SEARCH_STATE}/clearCurrent`,
                logout:                             'user/logout',
                instantDeleteAllCartsIfExpired:     'cart/deleteAllIfExpired',
                instantDeleteCurrentCart:           'cart/instantDeleteCurrentCart',
                updateInitialSearchState:           `${STORE_SEARCH_STATE}/updateInitial`,
                updateCurrentSearchState:           `${STORE_SEARCH_STATE}/update`,
                updateTopEventsFilter:              'topEvents/updateFilter',
                getUserCurrentLocation:             'user/location/getCurrentLocation',
                getTenantConfig:                    'appTenant/getConfig',
                getUserFavoritesIds:                'user/favoriteEntities/getIds',
                buildACLList:                       'user/ACL/buildACLList'

            } ),
            loadInitialSearchFromQuery( query ) {
                return this.updateInitialSearchState( searchUtilsNormalizeInitialStateFromQuery ( query ) );
            },
            overwriteCurrentSearchWithInitialSearch( ) {
                this.updateCurrentSearchState( this.initialSearch );
            },
            overwriteTopEventWithCity( ) {
                if ( this.initialSearch && this.initialSearch.city && this.initialSearch.city.name ) {
                    this.updateTopEventsFilter( { city: this.initialSearch.city } );
                } else if ( this.userHasCurrentLocationPosition ) {
                    this.updateTopEventsFilter( { city: this.userCurrentCityLocation } );
                }
            },
            showModal( to ) {
                let meta = to.meta;
                let modalConfig = meta.modalConfig;
                const componentProps =   Object.assign( {}, modalConfig.props, to.params );

                if ( modalConfig.showDynamic ) {
                    this.$modal.showDynamic(
                        modalConfig.component,
                        {
                            desktop: componentProps,
                            mobile: componentProps
                        },
                        {
                            desktop: modalConfig.configDesktop,
                            mobile: modalConfig.configMobile,
                        }
                    );

                    return;
                }

                const modalPropsOverwrite =  { ...this.$vuetify.breakpoint.mdAndUp ? modalConfig.configDesktop : modalConfig.configMobile };
                const showBottom = !!modalPropsOverwrite.showBottom;
                delete modalPropsOverwrite.showBottom;
                const modalProps = Object.assign(
                    {
                        'no-click-animation': true,
                        persistent: true,
                        scrollable: true,
                        'hide-overlay': true,
                        'retain-focus': false,
                    },
                    modalConfig.config,
                    modalPropsOverwrite
                );

                if( showBottom ) {
                    this.$modal.showBottom(
                        modalConfig.component,
                        componentProps,
                        modalProps
                    );
                    return;
                }

                this.$modal.show(
                    modalConfig.component,
                    componentProps,
                    modalProps
                );
            },
            applyBeforeEachOnRouter( ) {
                const router = this.$router;
                const filterEachRoute = async ( to, from, next ) => {
                    const handleCurrentSearchQuery = async ( ) => {
                        if ( await this.loadInitialSearchFromQuery( to.query ) ) {
                            this.overwriteCurrentSearchWithInitialSearch( );
                        } else {
                            if ( to.meta.type !== 'modal' ) {
                                this.clearCurrentSearchState( );
                            }
                        }
                    };

                    await handleCurrentSearchQuery( );
                    if ( from.name === 'cartCheckout' && to.name !== 'cartCheckout' ) {
                        const doDelete = () => {
                            this.instantDeleteCurrentCart( );
                        };
                        // Avoid cart deletion while cart submit process is running
                        if ( this.isCartSubmitting ) {
                            const unwatch = this.$watch( 'isSubmitting',
                                                         ( value ) => {
                                                             if ( !value && this.cart.id ) {
                                                                 doDelete();
                                                             }
                                                             unwatch();
                                                         } );
                        } else {
                            doDelete();
                        }
                    }
                    if ( await this.redirectToLogin ( to, from ) ) {
                        return;
                    }
                    if ( await this.redirectToLogout ( to, from ) ) {
                        return;
                    }
                    if ( to.meta.type === 'modal' ) {
                        this.showModal( to );
                    } else {
                        next( );
                    }
                };

                router.beforeEach( filterEachRoute );
            },
            stopStartSplashScreen( ) {
                const stopSplashScreen = window.stopSplashScreen;

                this.isSplashScreenShown = false;
                if ( stopSplashScreen ) {
                    stopSplashScreen( );
                }
            },
            setRelativeVh: debounce( function ( ) {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty( '--vh', `${vh}px` );
            }, 50 ),
        },
        /**
         * In you need anything related to routing (route/ router) put everything inside the onReady because we have dynamic loading of components.
         * @returns {Promise<void>}
         */
        async created( ) {
            this.loading = true;
            if ( this.userIsAuth && this.isSessionExpireIn ( 4 * 60 * 60 * 1000 ) ) {
                await this.redirectToLogout( );
                return;
            }

            this.instantDeleteAllCartsIfExpired( );

            await this.getTenantConfig( );
            await initDateFnsLocales( this.$i18n.i18next.languages );

            this.$router.onReady( async () => {
                const initAuth = await this.initAuth( );
                if ( initAuth === authenticationConstants.STATES.REDIRECTING
                    || initAuth === authenticationConstants.STATES.NEEDS_LOGOUT ) {
                    return;
                }
                if ( !this.userFirstAccess ) {
                    this.getUserFavoritesIds( { type: entityConstants.TYPES.EVENT } );
                    this.getUserFavoritesIds( { type: entityConstants.TYPES.ATTRACTION } );
                }

                this.buildACLList();

                this.getUserCurrentLocation( {} );
                this.loading = false;
                this.stopStartSplashScreen();

                this.loadInitialSearchFromQuery( this.$route.query );
                this.overwriteCurrentSearchWithInitialSearch();
                this.overwriteTopEventWithCity();
                this.applyBeforeEachOnRouter();
            } );

            if ( this.$vuetify.breakpoint.smAndDown && this.$device.hasIos ) {
                viewportUtilsSetMetaViewport( viewportConstants.IOS );
            }
            this.setRelativeVh( );
            window.addEventListener( 'resize', this.setRelativeVh );
        },
        beforeDestroy() {
            window.removeEventListener( 'resize', this.setRelativeVh );
        }
    };
</script>

<style lang="scss">
html {
    overflow-y: auto !important;
}

.v-application--wrap {
    min-height: calc( var( --vh, 1vh ) * 100 ) !important;
}
</style>
