import TheHome from '@core/views/home/TheHome';

const About             = ( ) => import( '@core/static/About/About' );
const Help              = ( ) => import( '@core/static/Help/Help' );
const Contact           = ( ) => import( '@core/static/Contact/Contact' );
const FAQ               = ( ) => import( '@core/static/FAQ/FAQ' );
const NotFound          = ( ) => import( '@core/static/NotFound' );
const TermsAgreement    = ( ) => import( '@core/static/TermsAgreement/TermsAgreement' );
const TermsOfUse        = ( ) => import( '@core/static/TermsOfUse/TermsOfUse' );
const PrivacyPolicy     = ( ) => import ( '@core/static/PrivacyPolicy/PrivacyPolicy' ) ;

const TheAttraction                 = ( ) => import( '@core/views/attraction/TheAttraction' );
const TheCheckoutWizard             = ( ) => import( '@core/views/cart/TheCheckoutWizard' );
const TheOrderConfirmation          = ( ) => import( '@core/views/cart/TheOrderConfirmation' );
const TheEvent                      = ( ) => import( '@core/views/event/TheEvent' );
const EventInfoModal                = ( ) => import( '@core/views/event/EventInfoModal' );
const EventReservation              = ( ) => import( '@core/views/event/TheEvent/EventReservation' );
const TheSearch                     = ( ) => import( '@core/views/search/TheSearch' );
const SearchResultTabsAll           = ( ) => import( '@core/views/search/TheSearch/SearchResultTabsAll' );
const SearchResultTabsAttractions   = ( ) => import( '@core/views/search/TheSearch/SearchResultTabsAttractions' );
const SearchResultTabsEvents        = ( ) => import( '@core/views/search/TheSearch/SearchResultTabsEvents' );
const SearchResultTabsVenues        = ( ) => import( '@core/views/search/TheSearch/SearchResultTabsVenues' );
const TheLogin                      = ( ) => import( '@core/views/authentication/TheLogin.vue' );
const TheLogout                     = ( ) => import( '@core/views/authentication/TheLogout.vue' );
const LoginPreviewModal             = ( ) => import( '@core/views/authentication/LoginPreviewModal.vue' );

const TheUserFavorites              = ( ) => import( '@core/views/user/TheUserFavorites' );
const UserFavoritesTabsEvents       = ( ) => import( '@core/views/user/TheUserFavorites/UserFavoritesTabsEvents' );
const UserFavoritesTabsAttractions  = ( ) => import( '@core/views/user/TheUserFavorites/UserFavoritesTabsAttractions' );
const TheUserTickets                = ( ) => import( '@core/views/user/TheUserTickets' );
const TheVenue                      = ( ) => import( '@core/views/venue/TheVenue' );
const SearchModalContent            = ( ) => import( '@core/shared/components/search/SearchModalContent' );
const StaticPagesModalWrapper       = ( ) => import( '@core/shared/components/wrappers/StaticPagesModalWrapper' );

const TheConfig                     = ( ) => import( '@core/views/app/TheConfig' );

export default [
    {
        path: '*',
        name: 'pageNotFound',
        component: NotFound,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/',
        name: 'home',
        component: TheHome,
        meta: {
            isPublic: true,
            sitemap: {
                priority:    1,
                changefreq: 'daily',
            }
        },

    },
    {
        path: '/login',
        name: 'home.login',
        component: TheHome,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true,
            }
        }
    },
    {
        name: 'attraction',
        path: '/attraction/:id',
        component: TheAttraction,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'attractionPartner',
        path: '/attraction-partner/:id',
        component: TheAttraction,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'cartCheckout',
        path: '/checkout/:cartId/:step?',
        component: TheCheckoutWizard,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'orderConfirmation',
        path: '/order-confirmation/:uuid?',
        component: TheOrderConfirmation,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'event',
        path: '/event/:id',
        component: TheEvent,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'event.reservation',
                path: '/event/:id/reservation',
                component: EventReservation,
                meta: {
                    layout: 'blank',
                    isPublic: true
                },
                props: true
            },
        ]
    },
    {
        name: 'tm-event',
        path: '/tm-event/:id',
        component: TheEvent,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'tm-event.reservation',
                path: '/tm-event/:id/reservation',
                component: EventReservation,
                meta: {
                    layout: 'blank',
                    isPublic: true
                },
                props: true
            },
        ]
    },
    {
        name: 'eventInfo',
        path: '/event/:id/info',
        meta: {
            type: 'modal',
            modalConfig: {
                component: EventInfoModal,
                props: {},
                config: { },
                configDesktop: {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    'max-width': 554,
                    'content-class': 'gst-v-dialog gst-v-dialog-event-info',
                },
                configMobile: {
                    'content-class': 'v-dialog__height-90 gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                }
            },
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/search',
        component: TheSearch,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                path: '/',
                name: 'search',
                component: SearchResultTabsAll,
                meta: {
                    isPublic: true
                },
            },
            {
                path: 'events',
                name: 'search.events',
                component: SearchResultTabsEvents,
                meta: {
                    isPublic: true
                },
            },
            {
                path: 'attractions',
                name: 'search.attractions',
                component: SearchResultTabsAttractions,
                meta: {
                    isPublic: true
                },
            },
            {
                path: 'venues',
                name: 'search.venues',
                component: SearchResultTabsVenues,
                meta: {
                    isPublic: true
                },
            }
        ]
    },
    {
        name: 'venue',
        path: '/venue/:id',
        component: TheVenue,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/userFavorites',
        component: TheUserFavorites,
        meta: {
            isPublic: false,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'userFavorites',
                path: '/',
                component: UserFavoritesTabsEvents,
                meta: {
                    isPublic: false
                }
            },
            {
                name: 'userFavorites.attractions',
                path: 'attractions',
                component: UserFavoritesTabsAttractions,
                meta: {
                    isPublic: false
                }
            }
        ]
    },
    {
        name: 'userTickets',
        path: '/userTickets',
        component: TheUserTickets,
        meta: {
            isPublic: false,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    // -- STATIC PAGES
    {
        path: '/help',
        name: 'help',
        component: Help,
        meta: {
            navigation: 'allMenus',
            label: 'help',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
        meta: {
            navigation: 'allMenus',
            label: 'faq',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            navigation: 'allMenus',
            label: 'about',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            //navigation: 'allMenus',
            label: 'contact',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        path: '/termsAgreement',
        name: 'termsAgreement',
        component: TermsAgreement,
        meta: {
            label: 'termsAgreement',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        path: '/termsOfUse',
        name: 'termsOfUse',
        component: TermsOfUse,
        meta: {
            navigation: 'footerOnly',
            label: 'termsOfUse',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        name: 'privacyPolicy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
            label: 'privacyPolicy',
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        }
    },
    {
        path: '/404',
        name: 'notFound',
        component: NotFound,
        meta: {
            layout: 'Default',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            layout: 'Default',
            component: TheLogout,
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/auth',
        name: 'auth',
        meta: {
            layout: 'Default',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                path: ':method',
                name: 'auth.login',
                component: TheLogin,
                meta: {
                    layout: 'Default',
                    isPublic: true
                },
            },

            /** route intercepted in App.vue, will never render a component **/
            {
                path: 'sso/redirect',
                name: 'auth.login.sso.redirect',
                meta: {
                    layout: 'Default',
                    isPublic: true,
                },
            },

            {
                path: 'sso/preview',
                name: 'auth.login.sso.preview',
                meta: {
                    layout: 'Default',
                    isPublic: true,
                    type: 'modal',
                    modalConfig: {
                        component: LoginPreviewModal,
                        props: {},
                        config: {
                        },
                        showDynamic: true,
                        configDesktop: {
                            'no-click-animation': true,
                            scrollable: true,
                            fullscreen: false,
                            'hide-overlay': false,
                            'max-width': 500,
                            'content-class': 'gst-v-dialog',
                        },
                        configMobile: {
                            'content-class': 'v-dialog__height-80 gst-v-dialog',
                            scrollable: true,
                            fullscreen: false,
                            'hide-overlay': false,
                            transition: 'dialog-bottom-transition'
                        }
                    },
                },
            },
        ],
    },
    // --- MODALS --- //
    {
        name: 'modalSearch',
        path: '/modal/search',
        meta: {
            type: 'modal',
            modalConfig: {
                component: SearchModalContent,
                props: {},
                config: {
                    fullscreen: true,
                    transition: 'dialog-bottom-transition'
                },
            },
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'modalStaticPages',
        path: '/modal/static-pages/:page',
        meta: {
            type: 'modal',
            modalConfig: {
                component: StaticPagesModalWrapper,
                props: {},
                config: {
                },
                showDynamic: true,
                configDesktop: {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    'max-width': 500,
                    'content-class': 'v-dialog__height-90 gst-v-dialog',
                },
                configMobile: {
                    'content-class': 'v-dialog__height-70 gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                }
            },
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    // --- HIDDEN --- //
    {
        name: 'appConfig',
        path: '/app/config',
        component: TheConfig,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
];
