import { mapActions, mapGetters, mapState } from 'vuex';

/**
 * Mixin that is like an interface for tenants that needs authentication
 * Each tenant can have different implementation of the SSO
 */
export default {
    computed: {
        ...mapState( {
            userNeedsLogout: state             => state.user.needsLogout,
        } ),
        ...mapGetters( {
            userEmail:                          'user/email',
            userIsAuth:                         'user/isAuth',
            isTenantAuthenticationTypeSSO:      'appTenant/isAuthenticationTypeSSO',
            hasAuthentication:                  'appTenant/hasAuthentication',
        } ),
    },
    watch: {
        userNeedsLogout: {
            handler: function ( value ) {
                //logout the user
                if ( value ) {
                    this.logout( true );
                }
            }
        }
    },
    methods: {
        ...mapActions( {
            getUserSession:                     'user/getSession',
            logout:                             'user/logout',
        } ),
        /**
         * Init Authentication
         * @returns {Promise<boolean>}
         */
        async initAuth( ) {
            //init AutheticationService
            //get user information
            //get session

            return true;
        },
        /**
         * Called on route change
         * When we are redirecting to login. We need to act on this.
         *
         * @param to
         * @param from
         * @returns {boolean}
         */
        // eslint-disable-next-line no-unused-vars
        async redirectToLogin ( to, from )  {
            const routeDecideAuthLogin = 'auth.login';
            const isAuthRedirect = to.name === routeDecideAuthLogin && to.params.method === 'redirect';
            const isAuthPreview = to.name === routeDecideAuthLogin && to.params.method === 'preview';

            if ( isAuthRedirect ) {
                if ( this.hasAuthentication ) {
                    //redirect to SSO Login
                }
                return true;
            }

            if ( isAuthPreview ) {
                if ( this.hasAuthentication ) {
                    this.$router.replace( { name: 'auth.login.sso.preview' } );
                }
                return true;
            }

            return false;
        },
        /**
         * Called on route change
         * When we are redirecting to logout. We need to act on this.
         *
         * @param to
         * @returns {Promise<boolean>}
         */
        async redirectToLogout( to ) {
            const routeLogout = 'logout';
            const isRouteLogout = to?.name === routeLogout;

            if ( isRouteLogout ) {
                await this.logout( true );
                return true;
            }
            return false;
        }
    }
};
