<template>
    <div>
        <transition name="fade">
            <div v-if="showOverlay && $vuetify.breakpoint.mdAndUp" class="overlay u-alpha-background" @click="setShowOverlay( false )" @keypress.esc="setShowOverlay( false )"></div>
        </transition>
    
        <BaseBanner
            ref="searchBanner"
            class="gst-banner--home d-flex flex-column">
            <template v-slot:gst-banner-content>
                <div class="d-flex align-center justify-center gst-banner--home__ticketmaster-tickets">
                    <div class="d-flex align-center justify-center gst-banner--home__ticketmaster-tickets-content">
                        <span>
                            {{ $t('ticketsFromTicketmaster') }}
                        </span>
                        <BaseIcon symbol-id="logo_ticketmaster_variant2" />
                    </div>
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h1 class="gst-home__title" v-html="$t('title', { 'interpolation': {'escapeValue': false} } )">
                </h1>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h3 class="gst-home__subtitle" v-html="$t('subtitle', { 'interpolation': {'escapeValue': false} } )">
                </h3>
                <div class="gst-home__search content-max-width mt-7 mt-md-12">
                    <SearchFieldBanner :show-overlay="showOverlay" tabindex="0" @click.native="setShowOverlay( true )" @keydown.esc.native="setShowOverlay( false )" />
                </div>
            </template>
        </BaseBanner>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import throttle from 'lodash/throttle';
    import debounce from 'lodash/debounce';
    import { isVisibleOnViewport as viewportUtilsIsVisibleOnViewport } from '@core/utils/viewportUtils';
    import BaseBanner from '@core/shared/components/misc/BaseBanner.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchFieldBanner from './SearchFieldBanner';
    

    export default {
        name: 'SearchBanner',
        components: {
            SearchFieldBanner,
            BaseBanner,
            BaseIcon
        },
        data( ) {
            return {
                showOverlay: false,
                searchBannerVisible: false,
                bonusPointsHomeBannerVisible: false
            };
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.theHome'
        },
        computed: {
            ...mapState( {
                homePageState: state => state.appState.page.home
            } )
        },
        methods: {
            ...mapActions( {
                setPagePropAppState: 'appState/setPageProp'
            } ),
            setPageStateShowHeader( value ) {
                this.setPagePropAppState( {
                    page: 'home',
                    prop: 'showHeader',
                    value
                } );
            },
            checkSearchBannerVisible( ) {
                const searchBanner = this.$refs.searchBanner;

                if ( searchBanner ) {
                    this.searchBannerVisible = viewportUtilsIsVisibleOnViewport( searchBanner.$el, 60 );
                } else {
                    this.searchBannerVisible = false;
                }
                if ( this.homePageState.showHeader !== !this.searchBannerVisible ) {
                    this.setPageStateShowHeader( !this.searchBannerVisible );
                }
            },
            checkSearchBannerVisibleDebounce: debounce( function ( ) {
                this.checkSearchBannerVisible( );
            }, 50 ),
            setShowOverlay( value ) {
                this.showOverlay = value;
            }
        },
        mounted( ) {
            this.throttleMenuClose = throttle( ( ) => {
                !this.$vuetify.breakpoint.mdAndUp || ( this.setShowOverlay( false ) );
            }, 20 );
            window.addEventListener( 'resize', this.throttleMenuClose );
            window.addEventListener( 'scroll', this.checkSearchBannerVisibleDebounce );
            window.addEventListener( 'resize', this.checkSearchBannerVisibleDebounce );
            setTimeout( this.checkSearchBannerVisible, 10 );
        },
        destroyed( ) {
            window.removeEventListener( 'resize', this.throttleMenuClose );
            window.removeEventListener( 'scroll', this.checkSearchBannerVisibleDebounce );
            window.removeEventListener( 'resize', this.checkSearchBannerVisibleDebounce );
        }
    };
</script>
<style scoped lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-home__title {
    @include heading(1);

    color: theme-color( 'white' );
    letter-spacing: -0.86px;
    text-align: center;

    @include mobile-only {
        font-size: font-size( 'xxxxxxl' );
        letter-spacing: -0.64px;
    }
}

.gst-home__subtitle {
    @include heading(5);

    color: theme-color( 'white' );
    letter-spacing: -0.16px;
    text-align: center;

    @include mobile-only {
        font-size: font-size( 'xxxl' );
        letter-spacing: -0.64px;
    }
}

.gst-banner--home {
    @include linear-gradient(to right, theme-color('home-banner-gradient-start') 0%, theme-color('home-banner-gradient-end') 100%);

    padding: 60px 0 55px;
    margin: theme-spacing( n3 );

    @include mobile-only {
        padding: 50px 0;
    }

    .gst-banner--home__ticketmaster-tickets {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .gst-banner--home__ticketmaster-tickets-content {
        padding: theme-spacing( 2, 4 );
        background: white;
        border-bottom-left-radius: border-radius( 'm' );
        border-bottom-right-radius: border-radius( 'm' );

        span {
            margin-right: theme-spacing( 2 );
            color: theme-color( 'quaternary' );
            font-size: font-size( 'xxs' );
        }

        svg {
            margin-bottom: 2px;
        }
    }

    .gst-home__search {
        form {
            width: 784px;
            border-radius: 50%;
            max-width: 100%;
        }
        
        form:focus {
            outline: none;
        }
    }
}

.view-container {
    height: 100%;
}

.gst-home .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: theme-color( 'transparent' ) !important;
    z-index: $z-index-header + 1;
    border-color: theme-color( 'transparent' ) !important;

    &::after {
        background: theme-color( 'overlay' );
        opacity: 0.4;
    }
}

@include mobile-only {
    .gst-banner--home {
        .gst-home__search {
            margin: unset;

            form {
                width: unset;
                max-width: 100%;
            }
        }
    }
}
</style>