<template>
    <component
        :is="tag"
        v-if="content"
        class="gst-bp-chip">
        <!-- eslint-disable-next-line vue/no-v-html  -->
        <p v-html="content"></p>
    </component>
</template>
<script>
    export default {
        name: 'BonusPointsChip',
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.bonusPoints.bonusPointsChip',
        },
        props: {
            content: {
                type: String,
                default: ''
            },
            tag: {
                type: String,
                default: 'div'
            }
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-bp-chip {
    display: inline-flex;
    padding-top: 2px;
    padding-bottom: 2px;
    background: theme-color( 'bonus-points-chip-bg-color' );
    color: theme-color( 'bonus-points-chip-color' );
    font-size: font-size( 'xxxs' );
    font-weight: font-weight( 'black' );
    min-height: 20px;
    border-radius: 16px;
    text-align: center;

    p {
        line-height: 12px;
        padding: theme-spacing( 0, 3 );
        margin-top: auto;
        margin-bottom: auto;
        vertical-align: bottom;
    }
}
</style>
