<template>
    <div
        class="gst-user-dropdown">
        <v-menu
            v-model="menuOpened"
            offset-y
            content-class="gst-user-dropdown-menu"
            :close-on-content-click="true">
            <template v-slot:activator="{ on }">
                <v-btn
                    ref="button"
                    text
                    outlined
                    depressed
                    :class="['gst-user-dropdown__activator', menuOpened && 'gst-user-dropdown__activator--active']"
                    v-on="on">
                    <BaseIcon class="gst-user-dropdown__icon gst-user-dropdown-menu-profile__icon me-1" symbol-id="icons--user" />
                    <div v-if="fullName">
                        {{ fullName }}
                        <span class="divider mx-2">•</span>
                    </div>
                    <div v-if="showUserBalance">
                        <span class="points">{{ $t("_common:terms.bonusPointWithCount", {count: userBalance, formattedCount: pointsFormated} ) }}</span>
                    </div>
                    <BaseIcon
                        class="gst-user-dropdown__icon ml-2"
                        :class="{'u-rotate-180': menuOpened }"
                        symbol-id="icons--dropdown_arrow" />
                </v-btn>
            </template>
            <div class="gst-menu-list pt-0">
                <ul>
                    <li class="gst-menu-list__item gst-menu-list__item--id">
                        <div class="gst-list-item__title">
                            {{ userDetail.memberId }}
                            <p v-if="formattedMemberStatus" class="gst-list-item__member-status mb-0">
                                {{ formattedMemberStatus }}
                            </p>
                        </div>
                    </li>
                    <li class="gst-menu-list__item">
                        <router-link
                            :class="'u-mouse-pointer'"
                            :to="{name:'userFavorites'}"
                            :target="userFavoritesTarget"
                            tabindex="0"
                            @click="menuOpened = false"
                            @keypress.enter="menuOpened = false">
                            <div class="gst-list-item__title">
                                {{ $t( '_common:pages.myFavorites.menu') }}
                            </div>
                        </router-link>
                    </li>
                    <li class="gst-menu-list__item gst-menu-list__item-logout">
                        <div class="gst-list-item__title u-mouse-pointer" tabindex="0" @click="onClickLogout" @keypress.enter="onClickLogout">
                            {{ $t( '_common:terms.logout') }}
                        </div>
                    </li>
                </ul>
            </div>
        </v-menu>
    </div>
</template>
<script>
    import UserMenuMixin from '@core/mixins/menus/UserMenuMixin';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'UserDropdownMenu',
        i18nOptions: { keyPrefix: 'common', namespaces: 'shared' },
        components: {
            BaseIcon,
        },
        mixins: [ UserMenuMixin ],
        computed: {
            userFavoritesTarget() {
                return this.$route.name === 'cartCheckout' ? '_blank' : '_self';
            },
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-user-dropdown-menu {
        margin-top: theme-spacing( 2 );
        border-radius: border-radius( 'm' ) !important;

        .gst-menu-list {
            background: theme-color( 'white' );
            min-width: 170px;

            ul {
                padding-left: theme-spacing( 0 );

                li {
                    list-style: none;
                }
            }
        }

        .gst-menu-list__item {
            display: flex;
            padding: theme-spacing( 0, 4 );
            padding-right: theme-spacing( 0 );
            align-items: center;
            flex: 1 1 100%;
            min-height: 48px !important;

            &::before {
                background-color: transparent;
            }

            .gst-list-item__member-status {
                margin-bottom: 0;
                font-size: font-size( 'xxs' );
                font-weight: font-weight( 'regular' );
            }

            .gst-list-item__title {
                line-height: line-height( 'xxl' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'medium' );

                &:hover {
                    color: theme-color( 'primary' ) !important;
                }
            }

            &.gst-menu-list__item--id {
                padding-right: theme-spacing( 4 );
                background-color: theme-color( 'primary-darken-1' );
                color: theme-color( 'white' ) !important;

                &:not(.v-list-item--active):not(.v-list-item--disabled) {
                    color: theme-color( 'white' ) !important;
                }

                .gst-list-item__title {
                    color: inherit;

                    &:hover {
                        color: inherit !important;
                    }
                }
            }
        }

        .gst-menu-list__item--active {
            .gst-list-item__title {
                color: theme-color( 'primary' ) !important;
            }

            svg .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .gst-menu-list__item--active::before {
            background-color: transparent !important;
        }
    }

    .gst-user-dropdown {
        .gst-user-dropdown-menu-profile__icon {
            display: none;
        }

        .v-btn.v-btn--outlined.v-btn--text.gst-user-dropdown__activator {
            height: 42px;
            width: auto;
            padding-right: theme-spacing( 1 );
            padding-left: 9px;
            border: 1px solid theme-color( 'primary' );
            font-size: font-size( 'xs' );
            font-weight: font-weight( 'bold' );
            border-radius: border-radius( 'm' ) !important;
            min-width: unset;
            text-transform: none;
            outline: none;
            transition: border 0.3s ease, background-color 0.3s ease;

            &::before {
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                background-color: theme-color( 'primary' );
            }

            &:hover::before {
                border: 1px solid theme-color( 'primary' );
                background-color: theme-color( 'primary' );
                opacity: 0.8;
            }

            &:hover {
                color: theme-color( 'white' );
                border-color: transparent;

                .gst-user-dropdown__icon .gst-svg-icon {
                    fill: theme-color( 'white' );
                }

                .divider,
                .points {
                    color: theme-color( 'white' );
                }
            }

            &:focus::before {
                background-color: theme-color( 'primary' );
            }

            svg {
                transition: all 0.3s ease;
                margin-left: -3px;
            }

            .gst-user-dropdown__icon .gst-svg-icon {
                fill: theme-color( 'primary' );
            }

            .divider {
                color: theme-color( 'senary' );
            }

            .points {
                color: theme-color( 'primary' );
                text-transform: lowercase;
            }
        }

        .v-btn.v-btn--outlined.v-btn--text.gst-user-dropdown__activator--active {
            border: 1px solid theme-color( 'primary' );
            background-color: transparent;
            color: theme-color( 'white' );
            transition: border 0.3s ease, background-color 0.3s ease;

            &::before {
                background-color: theme-color( 'primary' );
                opacity: 1;
            }

            &:hover {
                border-color: theme-color( 'primary' );
            }

            &:hover::before {
                background-color: theme-color( 'primary' );
                opacity: 0.8;
            }

            .gst-user-dropdown__icon .gst-svg-icon {
                fill: theme-color( 'white' );
            }

            .divider,
            .points {
                color: theme-color( 'white' );
            }
        }
    }
</style>
