<template>
    <IconLogo />
</template>

<script>
    import Logo from '@tenants/fanatics/assets/logo.svg';

    export default {
        components: {
            IconLogo: Logo,
        }
    };
</script>