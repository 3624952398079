<template>
    <BaseIcon class="gst-icon-heart-full" symbol-id="icons--heart_full" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconHeartFull',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";

    .gst-icon-heart-full {
        .gst-svg-icon {
            fill: theme-color( 'favorite-unmarked' );
        }

        .gst-svg-icon-2 {
            fill-opacity: 1 !important;
            fill: theme-color( 'favorite-marked' );
        }
    }
</style>
