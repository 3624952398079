import { entities, actions } from '../../../utils/constants/acl.js';

const STORE_NAME = 'userACL';

/**
 * Access Control List (ACLList)
 *
 * This logic replace isAuth validation on users and guest, if the configuration is
 * created in the tenant config.
 * ACLAllow: {
        guestCanPurchase: true
    }

    This validation is called from TheEvent.vue
    ACLList contains two elements, entities and actions those are defining the permissions
    to a section.
    Example: { entity: entities.TICKET, action: actions.PURCHASE }
    These elements are comming from constants in constants/acl.js
 */
const store = {
    name: STORE_NAME,
    namespaced: true,
    state: {
        ACLList: [  ],
        isGuest: false
    },
    mutations: {
        updateGuest( state, guest ) {
            state.isGuest = guest;
        },
        buildACLList( state, list ) {
            state.ACLList = list;
        }
    },
    actions: {
        buildACLList( { commit, rootGetters, rootState } ) {
            const ACLAllow = rootState.appTenant.config.ACLAllow;
            const isAuth = rootGetters['user/isAuth'];

            let list = [ ];

            if ( ACLAllow.guestCanPurchase || isAuth ) {
                list = [ { entity: entities.TICKET, action: actions.PURCHASE } ];
            }

            commit( 'buildACLList', list );
        },
        updateGuest( { commit }, isGuest ) {
            commit( 'updateGuest', isGuest );
        }
    },
    getters: {
        isGuest: ( state ) => {
            return state.isGuest;
        }
    }
};
export default store;