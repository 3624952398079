import { mapActions, mapGetters } from 'vuex';
import NotificationTerms from '@tenants/fanatics/components/notifications/NotificationTerms.vue';
import notificationTermsStore from '@tenants/fanatics/store/notificationTerms';

const STORE_NOTIFICATION_TERMS = 'notificationTerms';

const mixin = {
    components: {
        NotificationTerms
    },
    computed: {
        ...mapGetters( {
            show: `${STORE_NOTIFICATION_TERMS}/getVisible`,
            accepted: `${STORE_NOTIFICATION_TERMS}/getAccepted`,
            checkoutWizardCurrentStep: 'cart/checkoutWizardCurrentStep'
        } )
    },
    watch: {
        checkoutWizardCurrentStep: {
            handler: function ( newValue, oldValue ) {
                if ( newValue === 1 ) {
                    this.closeNotificationTerms();
                }
                if ( newValue === 0 && !this.accepted ) {
                    this.openNotificationTerms();
                }
                if ( newValue === 0 && oldValue === 2 ) {
                    this.closeNotificationTerms();
                    this.acceptTerms();
                }
            }
        }
    },
    methods: {
        ...mapActions( {
            openNotificationTerms: `${STORE_NOTIFICATION_TERMS}/open`,
            closeNotificationTerms: `${STORE_NOTIFICATION_TERMS}/close`,
            acceptTerms: `${STORE_NOTIFICATION_TERMS}/accept`
        } )  
    },
    created() {
        const store = this.$store;

        // register module only if doesn't exist
        if ( !( store && store.state && store.state[STORE_NOTIFICATION_TERMS] ) ) {
            store.registerModule( STORE_NOTIFICATION_TERMS, notificationTermsStore );
        }
    }
};

export default mixin;
