import Vue from 'vue';
import '@core/common';
import App from '@core/App.vue';
import TenantLogo from '@core/shared/components/TenantLogo.vue';
import TenantLogoIcon from '@core/shared/components/TenantLogoIcon.vue';
import i18nFactory from './i18nFactory';
import routerFactory from './routerFactory';
import storeFactory from './storeFactory';
import vuetifyFactory from './vuetifyFactory';
import imagesFactory from './imagesFactory';

export default {
    create: async ( config ) => {
        const tenantGlobalComponents = Object.assign (
            {
                'TenantLogo'                                  : TenantLogo,
                'TenantLogoIcon'                              : TenantLogoIcon,
                'TenantUserDropdownMenu'                      : () => import( '@core/shared/components/menu/UserDropdownMenu' ),
                'TenantUserMobileMenu'                        : () => import( '@core/shared/components/menu/UserMobileMenu' ),
            },
            config.globalComponents || { }
        );

        const i18n       = await i18nFactory.create( config.i18n || { } );
        const router     = routerFactory.create( { routes: config.routes || [ ] } );
        const vuetify    = vuetifyFactory.create( config.theme );
        const imagePaths = imagesFactory.create( { imagePaths: config.imagePaths || {} } );
        const store      = storeFactory.create( { ...config.store, imagePaths: imagePaths } );

        for ( const property in tenantGlobalComponents ) {
            Vue.component( property, tenantGlobalComponents[property] );
        }

        router.beforeEach( ( to, from, next ) => {
            if ( !to.meta.isPublic && !store.getters[ 'user/isAuth'] ) {
                router.replace( { name: 'home' } );
            } else {
                next( );
            }
        } );

        router.onError( error => {
            if ( /loading chunk [^\n]+ failed./i.test( error.message ) ) {
                window.location.reload( );
            }
        } );

        window.$store = store;

        const app = new Vue( {
            router,
            store,
            vuetify,
            i18n,
            render: h => h( App )
        } );
        app.$mount( '#app' );
    }
};
