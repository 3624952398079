import apiConstants from '@core/utils/constants/api';
import { createApiService, fetchAPI } from '@core/api/_utils';

let api = {
    wallet: {
        /**
         * Get user's wallet information from the server.
         * @deprecated Since version 2.8.7. Will be deleted in version 2.8.8.
         *  Use function `user.get` from module `apiServiceUser` instead.
         * @returns {*} The fetched response from the API.
         */
        getUserWallet: ( ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/wallet` );
        },
        createUserWallet: ( { email = '', memberDelegateToken = '' } ) => {
            let payload = { };

            if ( email ) {
                payload = { emailAddress: email };
            } else {
                payload = { memberDelegateURL: memberDelegateToken };
            }

            return fetchAPI.post( `${apiConstants.BASE_URL}/api/wallet`, payload );
        },
        registerUserWallet: ( { email = '' } ) => {
            return fetchAPI.post( `${apiConstants.BASE_URL}/api/wallet/register/${email}` );
        },
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}
