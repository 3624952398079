import hotjarConstants from '@core/utils/constants/hotjar';
import environmentConstants from '@core/utils/constants/environment';
import LogService from '@core/services/LogService';
import {
    getType as environmentUtilsGetType
} from './environmentUtils';

export function getSiteId( environmentType ) {
    switch ( environmentType ) {
    case environmentConstants.TYPES.TEST:
        return hotjarConstants.SITE_ID.TEST;
    case environmentConstants.TYPES.UAT:
        return hotjarConstants.SITE_ID.UAT;
    case environmentConstants.TYPES.PRODUCTION:
        return hotjarConstants.SITE_ID.PRODUCTION;
    default:
        return hotjarConstants.SITE_ID.LOCALHOST;
    }
}

export function getConfig() {
    const environmentType = environmentUtilsGetType();
    const isProduction = environmentType && environmentType !== environmentConstants.TYPES.LOCALHOST;
    const siteId = getSiteId( environmentType );

    if ( siteId ) {
        const config = {
            id: siteId,
            isProduction: isProduction
        };

        return config;
    } else {
        LogService.warn( `HotJar sideId for ${environmentType} environment is missing` );
        return false;
    }
}