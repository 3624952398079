<template>
    <BaseIcon class="gst-icon-close-round" symbol-id="icons--close_round" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconClose',
        components: {
            BaseIcon
        }
    };
</script>
