<template>
    <BaseIcon class="gst-icon-heart-hover-bordered" symbol-id="icons--heart_hover_bordered" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconHeartHoverBordered',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";

    .gst-icon-heart-hover-bordered .gst-svg-icon {
        fill: theme-color( 'primary' );
    }
</style>
