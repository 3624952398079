const STORE_NAME = 'profile';
import apiServiceUser from '@core/api/apiServiceUser';
import mutationTypes from '@core/helpers/storeFactories/helpers/mutationTypes';
import LogService from '@core/services/LogService';

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        loading: false,
        error: false,
        sessionId: null
    },
    getters: {
        loading: state => state.loading,
        hasError: state => state.error
    },
    mutations: {
        setDetail( state, data ) {
            state.detail = data;
        },
        setError( state, isError ) {
            state.error = isError;
        },
        setLoading( state, loading ) {
            state.loading = loading;
        },
        setSessionId( state, sessionId ) {
            state.sessionId = sessionId;
        },
        [mutationTypes.CLEAR]( state ) {
            state.error = false;
            state.sessionId = null;
        }
    },
    actions: {
        /**
         * Hack until we have an session with points.com
         */
        getSession: async ( { commit }, email ) => {
            const api = await apiServiceUser( '' );
            const response = await api.user.getSession( { email } );
            if ( response.success ) {
                commit( 'setSessionId', response.data );
                return response.data;
            } else {
                LogService.debug( 'problems loading session for ' + email );
                commit( 'clear' );
                return null;
            }
        },
        setError( { commit }, isError ) {
            commit( 'setError', isError );
        },
        setLoading( { commit }, loading = true ) {
            commit ( 'setLoading', loading );
        }
    },
    _persistent: {
        getDataToSave: ( state ) => {
            return {
                sessionId: state[STORE_NAME].sessionId,
            };
        },
        getDataToLoad: ( data ) => {
            return {
                sessionId: data.sessionId,
            };
        }
    }
};
