import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import merge from 'lodash/merge';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import appConstants from '@core/utils/constants/app';
import LogService from '@core/services/LogService';
import { sentenceCaseProcessor } from '@core/helpers/i18n/customPostProcessor';

const namespaces = [ 'main', 'shared', '_common' ];

const getCoreLocalesFiles = ( language ) => {
    const files = namespaces.map( async namespace => {
        try {
            return ( {
                namespace: namespace,
                promise: await import( '@core/locales/' + namespace + '/' + language )
            } );
        } catch ( e ) {
            return null;
        }

    } );

    return Promise.all( files ).then( results => results.filter( result => result !== null ) );
};

/**
 * Merge core translations with custom translations for the tenant
 * @param languages     - all supported languages by the application
 * @param customLocales - custom translation for the tenant
 * @returns {Promise<{ }>}
 */
const getResources = async ( languages, customLocales = {} ) => {
    let ret = {};

    if ( !languages || !languages.length ) {
        return ret;
    }

    for ( const lang of languages ) {
        const coreLocales = await getCoreLocalesFiles( lang );
        const defaultLocales = coreLocales.reduce( ( ret, locale ) => {
            if ( locale ) {
                ret[locale.namespace] = locale.promise.default;
            }
            return ret;
        }, {} );

        ret[lang] = {
            _common: ( customLocales._common && customLocales._common[lang] ) ? merge( defaultLocales['_common'], customLocales._common[lang] ) : defaultLocales['_common'],
            main: ( customLocales.main && customLocales.main[lang] ) ? merge( defaultLocales['main'], customLocales.main[lang] ) : defaultLocales['main'],
            shared: ( customLocales.shared && customLocales.shared[lang] ) ? merge( defaultLocales['shared'], customLocales.shared[lang] ) : defaultLocales['shared'],
        };
    }

    return ret;
};

export default {
    create:  async ( { languages, locales } = {} ) => {
        const resources = await getResources( languages, locales || {} );
        const localStorageDefaultVersion = `v${appConstants.VERSION}-${appConstants.BUILD_TIMESTAMP}`;

        i18next
            .use( ChainedBackend )
            .use( sentenceCaseProcessor )
            .init( {
                debug: process.env.NODE_ENV !== 'production',
                lng: languages[0],
                fallbackLng: languages,
                ns: [
                    '_common',
                    'shared',
                    'main'
                ],
                resources,
                parseMissingKeyHandler: ( ) => {
                    if ( process.env.NODE_ENV === 'production' )
                        LogService.warn( 'Missing Key from translation file' );
                    return 'Invalid Key';
                },
                interpolation: {
                    format: ( value, format ) => {
                        switch ( format ) {
                        case 'uppercase':
                            return value.toUpperCase( );
                        case 'lowercase':
                            return value.toLowerCase( );
                        default:
                            return value;
                        }
                    }
                },
                postProcess: [ sentenceCaseProcessor.name || null  ],
                backend: {
                    backends: [
                        LocalStorageBackend,
                        resourcesToBackend( ( language, namespace, callback ) => {
                        // Note: Using + operator for concat strings.
                        // Importing paths with template strings does not pass lint rule e.g: import( `@core/locales/${namespace}/${language}.json` )
                            import( '@core/locales/' + namespace + '/' + language + '.json' )
                                .then( ( res ) => {
                                    const commonResources = resources[language][namespace];
                                    const mergedResources = merge( res, commonResources );

                                    callback( null, mergedResources );
                                } )
                                .catch( ( error ) => {
                                    callback( error, null );
                                } );
                        } )
                    ],
                    backendOptions: [
                        {
                            prefix: 'gstI18n_',
                            expirationTime: process.env.NODE_ENV !== 'production' ? 0 : 7 * 24 * 60 * 60 * 1000,
                            defaultVersion: localStorageDefaultVersion,
                            store: typeof window !== 'undefined' ? window.localStorage : null
                        }
                    ]
                }
            } )
            .then( () => {
                i18next.reloadResources( );
            } );

        Vue.use( VueI18Next );

        return new VueI18Next( i18next );
    }
};
