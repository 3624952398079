<template>
    <div class="gst-header-mobile-search">
        <v-text-field
            :placeholder="$t('placeholderMobile')"
            single-line
            hide-details
            flat
            data-hj-allow
            @click="$router.push( { name: 'modalSearch' } )">
            <template slot="prepend-inner">
                <BaseIcon class="search-icon ml-3 mr-1" symbol-id="icons--search" />
            </template>
        </v-text-field>
    </div>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'HeaderSearchBar',
        components: {
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.search.searchFieldBanner'
        }
    };
</script>
<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

.gst-header-mobile-search {
    .v-input__slot {
        height: theme-spacing( 9 ) !important;
        width: 180px;
        background-color: theme-color( 'quinary' ) !important;
        border-radius: border-radius( 'l' );
    }

    .v-input__slot::before,
    .v-input__slot::after {
        border-style: none !important;
    }

    .v-input__control {
        max-width: 157px !important;
        min-height: none !important;
        justify-content: center !important;
    }

    input {
        color: theme-color( 'tertiary' ) !important;
        font-size: font-size( 'xs' );

        @include mobile-only {
            font-size: font-size( 'l' ) !important;
        }
    }
}

svg.gst-base-icon.search-icon {
    display: flex !important;
    padding-top: theme-spacing( 1 );
    max-width: 19px !important;

    .gst-svg-icon {
        fill: theme-color( 'tertiary' ) !important;
    }
}

.search-text {
    display: flex !important;
    font-size: font-size( 'xs' ) !important;
    align-items: center !important;
}
</style>