<template>
    <router-link :to="{ name:'event', params: { id: event.id } }" :data-test-id="$attrs['data-test-id']">
        <div class="gst-event-card gst-event-card-variant-2 mx-auto u-box-shadow">
            <BaseImage
                :src="imageSrc"
                class="gst-event-card-variant-2__image"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="230px"
                @error="errorLoadingImage = true">
                <template v-slot:content>
                    <div class="gst-event-card-variant-2__content d-flex flex-column u-height-100">
                        <div class="d-flex justify-space-between">
                            <DateIcon :start-date="event.startDateTime" :end-date="event.endDateTime" />
                            <div class="gst-event-card-variant-2__bonus-points-chip py-0 pr-0 flex d-flex justify-end align-self-start">
                                <BonusPointsChip
                                    v-if="hasTicketAvailable"
                                    :points="event.bonusPoints"
                                    :currency="loyaltyCurrency || currency"
                                    :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney" />
                            </div>
                            <FavoriteEntityButton :entity-type="entityType" :entity-id="event.id" class="gst-event-card-variant-2__fav flex align-self-start shrink" />
                        </div>
                        <div class="flex-grow-1 d-flex flex-column justify-end">
                            <div class="gst-event-card-variant-2__title d-flex align-start justify-end flex-column u-width-100">
                                <VClamp
                                    autoresize
                                    :max-lines="2"
                                    class="hyphens clamp">
                                    {{ event.name }}
                                </VClamp>
                            </div>
                            <div class="d-flex flex-grow-0 flex-row">
                                <div class="d-flex flex-column justify-end flex-grow-1">
                                    <VenueInfo
                                        :max-lines="1"
                                        class="pr-2"
                                        :item="venueInfoLocation">
                                        <template v-if="eventDateTime">
                                            {{ eventDateTime }} &#183;
                                        </template>
                                    </VenueInfo>
                                </div>
                                <div
                                    :style="stylePriceContainer"
                                    class="gst-event-card-variant-2__price flex justify-end text-right">
                                    <span class="d-block price_from">{{ $t('_common:terms.fromPrice') }}</span>
                                    <span class="d-block price">{{ event.minPrice | currencyFull( event.currency ) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </BaseImage>
        </div>
    </router-link>
</template>
<script>
    import VClamp from 'vue-clamp';
    import categoriesConstants from '@core/utils/constants/categories';
    import entityConstants from '@core/utils/constants/entity';
    import {
        hasTicketAvailable as eventUtilsHasTicketAvailable
    } from '@core/utils/eventUtils';
    import DateIcon from '@core/shared/components/event/DateIcon.vue';
    import FavoriteEntityButton from '@core/shared/components/buttons/FavoriteEntityButton.vue';
    import VenueInfo from '@core/shared/components/containerInfo/VenueInfo.vue';
    import BonusPointsChip from '@core/shared/components/bonusPoints/BonusPointsChip.vue';
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';

    export default {
        name: 'EventCardVariant2',
        components: {
            DateIcon,
            FavoriteEntityButton,
            VClamp,
            VenueInfo,
            BonusPointsChip,
            BaseImage
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            isLoyaltyCurrencyTypeMoney: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                errorLoadingImage: false
            };
        },
        computed: {
            imageSrc() {
                const { errorLoadingImage, fallbackSrc } = this;
                const { imageUrl } = this.event;

                if ( errorLoadingImage ) {
                    return this.fallbackSrc;
                } else {
                    return imageUrl || fallbackSrc;
                }
            },
            fallbackSrc() {
                let found = categoriesConstants.LIST.find( item => item.ID === this.event.classificationSegmentId );
                return found ? found.FALLBACK : categoriesConstants.DEFAULT_FALLBACK ;
            },
            entityType() {
                return entityConstants.TYPES.EVENT;
            },
            eventDateTime( ) {
                const { startDateTime, hasStartTime } = this.event;

                if ( startDateTime ) {
                    return hasStartTime ? this.$options.filters.time( this.event.startDateTime ) : '';
                }
                return this.$t( '_common:terms.dateAndTimeToBeDetermined' );
            },
            venueInfoLocation( ) {
                const { venueName, venueStreet, venueCity, venueState, venueCountry } = this.event;

                return {
                    name:          venueName,
                    street:        venueStreet,
                    city:          venueCity,
                    stateCode:     venueState,
                    countryCode:   venueCountry
                };
            },
            hasTicketAvailable( ) {
                return eventUtilsHasTicketAvailable( this.event );
            },
            currency( ) {
                return this.event.currency;
            },
            loyaltyCurrency( ) {
                return this.event.loyaltyCurrency;
            },
            /**
             * Need to preserve the space so layout will not brake
             * because of the image height and the way we did the
             * layout
             * TODO: maybe we can change the layout
             */
            stylePriceContainer( ) {
                if ( this.event.minPrice && this.hasTicketAvailable ) {
                    return '';
                }
                return 'visibility:hidden';
            }
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";

.gst-event-card-variant-2 {
    position: relative;
    line-height: 1.3;
    max-width: 100%;
    border-radius: border-radius( 'm' );
    
    .gst-event-card-variant-2__image {
        border-radius: border-radius( 'm' );
    }

    .gst-event-card-variant-2__content {
        padding: theme-spacing( 2, 2, 4, 4 );
    }

    .gst-event-card-variant-2__bonus-points-chip {
        overflow: hidden;
    }

    .gst-event-card-variant-2__fav {
        margin: theme-spacing( n2, n2, 0, 0 );
    }

    .gst-event-card-variant-2__title {
        margin-bottom: theme-spacing( n4 );
        font-size: font-size( 'xxxxxl' );
        font-weight: font-weight( 'bold' );
        letter-spacing: -0.88px;
        min-height: 100px;
        -webkit-hyphens: initial;
        -ms-hyphens: initial;
        hyphens: initial;

        .clamp {
            max-width: 87%;
        }
    }

    .gst-event-card-variant-2__price {
        .price_from {
            font-size: font-size( 'xxxs' );
            font-weight: font-weight( 'black' );
            letter-spacing: 1px;
            text-transform: uppercase;
            opacity: 0.6;
        }

        .price {
            font-size: font-size( 'xxl' );
            font-weight: font-weight( 'medium' );
        }
    }
}
</style>
