<template>
    <v-menu
        v-model="menuOpened"
        offset-y
        content-class="gst-city-search-store-dropdown__menu"
        :close-on-content-click="false"
        :disabled="disabled">
        <template v-slot:activator="{ on }">
            <span
                class="gst-city-search-store-dropdown__target u-mouse-pointer order-1"
                :class="{
                    'tertiary--text': !menuOpened && toggleTextColor,
                    'primary--text': menuOpened || !toggleTextColor,
                    'gst-search-input--placeholder': isPlaceholder,
                }"
                :data-test-id="$attrs['data-test-id']"
                v-on="on">
                {{ selectValueLabel }}
            </span>
            <i
                class="gst-city-search-store-dropdown__icon gst-icon-svg u-mouse-pointer order-2"
                :class="{
                    'gst-icon-svg--active u-rotate-180': menuOpened,
                    'tertiary--text': !menuOpened && toggleIconColor,
                    'primary--text': menuOpened || !toggleIconColor,
                }"
                v-on="on">
                <BaseIcon class="gst-dropdown-icon-svg" symbol-id="icons--dropdown_arrow" />
            </i>
        </template>
        <v-list>
            <v-list-item v-if="hasSearchOption">
                <v-text-field
                    ref="inputSearch"
                    v-model="searchValue"
                    :placeholder="$t('inputPlaceholder')"
                    color="primary"
                    class="gst-search-input pt-0"
                    hide-details=""
                    data-hj-allow>
                    <template slot="prepend-inner" class="text-primary">
                        <BaseIcon class="gst-search-icon-svg" symbol-id="icons--search" />
                    </template>
                </v-text-field>
            </v-list-item>
            <v-list-item v-if="canFindMyLocation && hasCurrentLocationOption" key="gps" class="gst-search-city-menu__current-location" @click="findMyLocation">
                <v-list-item-icon>
                    <BaseIcon symbol-id="icons--gps" />
                </v-list-item-icon>
                <v-list-item-title>
                    <v-flex class="d-flex flex-row justify-center">
                        {{ $t('_common:terms.useMyCurrentLocation') }}
                        <data-loading v-if="loadingCurrentLocation" :width="2" :size="20" class="ml-1" />
                    </v-flex>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="clearable" class="gst-city-search-store-dropdown__reset" :disabled="!value" :ripple="false" @click="select( { } )">
                <v-list-item-title>
                    <div>{{ $t('_common:terms.reset') }}</div>
                </v-list-item-title>
            </v-list-item>
            <v-divider v-if="clearable" />
            <v-list-item v-if="showAllOption" class="gst-city-search-store-dropdown__clear" @click="select( { } )">
                <v-list-item-title>
                    <div>{{ $t('labels.clear') }}</div>
                </v-list-item-title>
            </v-list-item>
            <DataLoading v-if="loading" :width="2" />
            <template v-else>
                <p v-if="!cities.length" class="gst-city-search-store-dropdown__no-data">
                    {{ $t('messages.noData') }}
                </p>
                <SearchCityList class="gst-city-search-store-dropdown__list" :items="cities" :highlight="searchValue" @select="select" />
            </template>
        </v-list>
    </v-menu>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import debounce from 'lodash/debounce';
    import listConstants from '@core/utils/constants/list';
    import {
        hasLocation as cityObjectUtilsHasLocation,
        hasCurrentLocation as cityObjectUtilsHasCurrentLocation
    } from '@core/utils/cityObjectUtils';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import SearchCityList from '@core/shared/components/search/SearchCityList.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'CitySearchStoreDropDown',
        inheritsAttribute: true,
        components: {
            DataLoading,
            SearchCityList,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.input._common.cityDropDown',
        },
        props: {
            value: {
                type: Object,
                default: ( ) => { return { name: '' }; }
            },
            showIcon: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: true
            },
            hasCurrentLocationOption: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            offset: {
                type: Number,
                default: 0
            },
            limit: {
                type: Number,
                default: 1000
            },
            onlyFavourite: {
                type: Boolean,
                default: false
            },
            hasSearchOption: {
                type: Boolean,
                default: false
            },
            showAllOption: {
                type: Boolean,
                default: true
            },
            toggleTextColor: {
                type: Boolean,
                default: true
            },
            toggleIconColor: {
                type: Boolean,
                default: true
            }
        },
        data( ) {
            return {
                loadingCurrentLocation: false,
                menuOpened: false,
                searchValue: ''
            };
        },
        computed: {
            ...mapState( {
                cities:   state => state.cities.list,
                loading:  state => state.cities.loading
            } ),
            canFindMyLocation( ) {
                return !!navigator.geolocation;
            },
            selectValueLabel( ) {
                const { value } = this;
                if ( value ) {
                    const cityLabel = this.$options.filters.city( value, { country: true } );

                    if ( cityLabel ) {
                        return cityLabel;
                    }

                    if ( cityObjectUtilsHasLocation ( value ) ) {
                        if ( cityObjectUtilsHasCurrentLocation( value ) ) {
                            return this.$t( '_common:terms.myCurrentLocation' );
                        } else {
                            return this.$t( '_common:terms.location' );
                        }
                    }
                }
                return this.$t( 'placeholder' );
            },
            isPlaceholder( ) {
                return this.selectValueLabel === this.$t( 'placeholder' );
            }
        },
        watch: {
            'menuOpened': function ( newValue ) {
                if ( newValue ) {
                    this.unwatchSearchValProp = this.$watch( 'searchValue', (  ) => {
                        this.loadDataDebouced( );
                    } );
                    setTimeout( ( ) => {
                        !this.$refs.inputSearch || this.$refs.inputSearch.focus( );
                    }, 100 );
                    this.$emit( 'open' );
                } else {
                    !this.unwatchSearchValPropAfterValueChange || this.unwatchSearchValPropAfterValueChange( );
                }
            }
        },
        methods: {
            ...mapActions( {
                loadCities: 'cities/get',
                getCurrentLocation: 'user/location/getCurrentLocation',
            } ),
            getHTMLCity( item ) {
                const regex = new RegExp( `(${this.searchValue })`, 'gi' );

                return {
                    ...item,
                    name: item.name.replace( regex, '<mark>$1</mark>' )
                };
            },
            select( value ) {
                this.$emit( 'input', value );
                this.menuOpened = false;
            },
            loadDataDebouced: debounce( function ( ) {
                const { offset, limit } = this;
                this.loadCities( { refresh: true, search: { keyword: this.searchValue, limit: limit, offset: offset } } );
            }, listConstants.DEBOUNCE_DELAY ),
            async findMyLocation( ) {
                if ( !this.loadingCurrentLocation ) {
                    this.loadingCurrentLocation = true;
                    const location = await this.getCurrentLocation( { showMessage: false, refresh: true } );

                    if ( location && location.position ) {
                        this.select( {
                            ...location.city,
                            location: {
                                current: true,
                                ...location.position
                            }
                        } );
                    }
                    this.loadingCurrentLocation = false;
                }
            }
        },
        mounted( ) {
            const { offset, limit, onlyFavourite, hasSearchOption, searchValue } = this;
            const defaultRequestData = {
                refresh: true,
                search: {
                    offset: offset,
                    limit: limit,
                    onlyFavourite: onlyFavourite
                }
            };

            if ( hasSearchOption ) {
                const requestData = {
                    ...defaultRequestData,
                    keyword: searchValue,
                };

                this.unwatchSearchValPropOnStart =  this.$watch( 'menuOpened', ( newValue ) => {
                    if ( newValue ) {
                        this.loadCities( requestData );
                        this.unwatchSearchValPropOnStart( );
                    }
                } );
            } else {
                const requestData = {
                    ...defaultRequestData
                };

                this.loadCities( requestData );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-city-search-store-dropdown__menu {
        margin-top: theme-spacing( 2 );
        background-color: theme-color( 'white' );
        border-radius: border-radius( 'm' ) !important;
    }

    .gst-city-search-store-dropdown__target {
        display: inline-block;
        line-height: 0.85;
        border-bottom: 1px solid currentColor;
    }

    .gst-city-search-store-dropdown__clear {
        padding-top: theme-spacing( 2 );
        padding-bottom: theme-spacing( 2 );
        font-size: font-size( 'l' );
        min-height: 16px;
    }

    .gst-search-city-menu__current-location {
        padding-top: theme-spacing( 2 );
        padding-bottom: theme-spacing( 2 );
        min-height: 16px;

        .v-list-item__icon {
            margin-top: theme-spacing( 0 );
            margin-bottom: theme-spacing( 0 );
            margin-right: theme-spacing( 2 ) !important;

            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }
    }

    .gst-city-search-store-dropdown__icon {
        .gst-dropdown-icon-svg {
            .gst-svg-icon {
                fill: currentColor;
            }
        }
    }

    .gst-city-search-store-dropdown__list .v-list-item {
        padding-top: theme-spacing( 2 );
        padding-bottom: theme-spacing( 2 );
        font-size: font-size( 'l' );
        min-height: 16px;
    }

    .gst-city-search-store-dropdown__list .v-list-item div mark {
        background-color: transparent;
        font-weight: font-weight( 'medium' );
    }

    .gst-city-search-store-dropdown__no-data {
        padding: theme-spacing( 4 );
        color: theme-color( 'error' );
        font-size: font-size( 'xxxs' );
        font-weight: font-weight( 'medium' );
        text-align: center;
    }

    .gst-city-search-store-dropdown__reset {
        padding-top: theme-spacing( 2 );
        padding-bottom: theme-spacing( 2 );
        font-size: font-size( 'l' );
        min-height: 16px;
    }

    .gst-city-search-store-dropdown__reset::before {
        opacity: 0 !important;
    }

    .gst-city-search-store-dropdown__reset.v-list-item--disabled,
    .gst-city-search-store-dropdown__reset.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: theme-color( 'primary' ) !important;
    }

    .gst-city-search-store-dropdown__reset.v-list-item--disabled {
        opacity: 0.4;
    }
</style>
