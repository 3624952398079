<template>
    <BaseIcon class="gst-icon-info-round" symbol-id="icons--info_round" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconInfoRound',
        components: {
            BaseIcon
        }
    };
</script>
