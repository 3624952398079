import { formatDefaultShort } from '@core/utils/currencyUtils';

/**
 * Currency value for 'en-US' locale ( no manipulation of the data )
 * @param {Number} value
 * @param {String} currency
 * @param {Object} options
 */
export default function currencyFilter( value, currency ) {
    return formatDefaultShort( value, currency );
}
