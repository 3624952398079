import { mapActions, mapState } from 'vuex';
import truncate from 'lodash/truncate';
import loyaltyConstants from '@core/utils/constants/loyalty';

let mixin = {
    data: function() {
        return {
            menuOpened: false
        };
    },
    props: {
        userDetail: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapState( {
            tenantCurrencyType: state => state.appTenant.config.loyalty.currencyType
        } ),
        showUserBalance() {
            return this.tenantCurrencyType === loyaltyConstants.CURRENCY_TYPES.POINTS;
        },
        pointsFormated( ) {
            return this.$options.filters.bonusPoints( this.userDetail.balance );
        },
        fullName() {
            const ret = [ this.userDetail?.firstName, this.userDetail?.lastName ].filter( Boolean ).join( ' ' ) || null;

            return ret && !this.$vuetify.breakpoint.mdAndDown ? truncate( ret, { length: 20, } ) : ret;
        },
        userBalance() {
            const number = Number( this.userDetail.balance );
            return isNaN( number ) ? 0 : number;
        },
        formattedMemberStatus() {
            const status = this.userDetail.memberStatus;
            return status && this.$t( 'shared:common.user.memberStatus', { status: status } );
        }
    },
    methods: {
        ...mapActions( {
            showLoadingOverlay:             'appState/setLoading',
        } ),
        onClickLogout() {
            this.show = false;
            setTimeout( () => {
                this.showLoadingOverlay( true );
                this.$router.push( { name: 'logout' } );
            }, 500 );
        }
    },
};

export default mixin;
