import normalizeTeamsList from './normalizeTeamsList';

export default function ( { id, name, logo,  banner_url, teams } ) {
    return {
        name,
        id,
        logo,
        bannerUrl: banner_url,
        teams: teams ? normalizeTeamsList( teams ) : [ ]
    };
}