import fetch from '@core/utils/fetch';

export default {
    addToRequest: ( ) => {
        const urlParams = new URLSearchParams( window.location.search );
        const token = urlParams.get( 'token' );

        if ( token ) {
            fetch.service.interceptors.request.use( 
                ( config ) => {
                    config.headers[ 'GST-DISCOUNT-TOKEN' ] = token; 
    
                    return config;
                },
                ( error ) => {
                    return Promise.reject( error );
                }
            );
        }
    }
};