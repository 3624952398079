<template>
    <div>
        <v-main class="blank-footer">
            <!-- Provides the application the proper gutter -->
            <v-container fluid class="blank-layout-with-footer py-0 px-0">
                <slot></slot>
            </v-container>
            <Footer />
        </v-main>
    </div>
</template>

<script>
    import Footer from './components/Footer.vue';

    export default {
        name: 'BlankWithFooter',
        components: {
            Footer
        }
    };
</script>
