import commonRoutes from '@core/routes/index';

export function getFinalRoutes( routes ) {
    let finalRoutes = [ ...commonRoutes ];

    if( !routes || routes.length === 0 ) {
        return finalRoutes;
    }

    if ( Array.isArray( routes ) && routes.length ) {
        finalRoutes = commonRoutes
            .map( commonRoute => {
                const customRoute = routes.find( itemCustomRoute => itemCustomRoute.name === commonRoute.name );
                return {
                    ...commonRoute,
                    ...customRoute,
                    path: commonRoute.path
                };
            } );
        return finalRoutes;
    }
    if ( !Array.isArray( routes ) && ( routes.add || routes.edit ) ) {
        if ( routes.edit ) {
            finalRoutes = commonRoutes
                .map( commonRoute => {
                    const editedRoute = routes.edit.find( route => route.name === commonRoute.name );
                    return {
                        ...commonRoute,
                        ...editedRoute,
                        path: commonRoute.path
                    };
                } );
        }
        if( routes.add ) {
            return finalRoutes = [
                ...finalRoutes,
                ...routes.add
            ];
        }
        return finalRoutes;
    }
}
