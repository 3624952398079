import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import imageConstants from '@core/utils/constants/imagePaths';

export default {
    create: ( config = {} ) => {
        let imagePaths = cloneDeep( imageConstants );
        if ( config.imagePaths ) {
            return merge( imagePaths, config.imagePaths );
        }

        return imagePaths;
    }
};
