<template>
    <v-btn
        :class="['gst-btn gst-btn-clasification', $vuetify.breakpoint.lgAndUp && 'gst-btn-clasification-hover']"
        depressed
        v-bind="$attrs"
        @click="$emit('click')">
        <BaseIcon v-if="icon" class="gst-btn-icon" :symbol-id="icon" />
        {{ text }}
    </v-btn>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'CategoryFilterButton',
        components: {
            BaseIcon
        },
        inheritAttrs: true,
        props: {
            icon: {
                type: String,
                required: true
            },
            text: {
                type: String,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-btn.v-btn.gst-btn-clasification {
        height: 60px !important;
        background-color: theme-color( 'quinary' ) !important;
        color: theme-color( 'tertiary' );
        border-color: theme-color( 'septenary' ) !important;
        opacity: 1 !important;

        svg ::v-deep .gst-svg-icon {
            transition: all 0.5s ease;
        }

        &.v-item--active {
            background-color: theme-color( 'primary' ) !important;
            color: theme-color( 'white' );
            border-color: theme-color( 'primary' ) !important;

            svg ::v-deep .gst-svg-icon {
                fill: theme-color( 'white' );
            }
        }
    }

    .gst-btn.v-btn.gst-btn-clasification-hover:hover {
        background-color: theme-color( 'primary' ) !important;
        color: theme-color( 'white' );
        border-color: theme-color( 'primary' ) !important;

        svg ::v-deep .gst-svg-icon {
            fill: theme-color( 'white' );
        }
    }
</style>
