<template>
    <BaseIcon class="gst-icon-heart-stroke" symbol-id="icons--heart_stroke" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconHeartStroke',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";

    .gst-icon-heart-stroke {
        .gst-svg-icon {
            fill: theme-color( 'favorite-unmarked' );
        }
    }

    .active {
        .gst-icon-heart-stroke {
            .gst-svg-icon {
                fill: theme-color( 'favorite-unmarked' );
            }

            .gst-svg-icon-2 {
                fill-opacity: 1 !important;
                fill: theme-color( 'favorite-marked' );
            }
        }
    }
</style>
