<template>
    <v-btn
        center
        text
        class="gst-login-btn"
        v-bind="$attrs"
        primary
        @click="$emit('click', $event)">
        <BaseIcon class="gst-login-btn__icon" symbol-id="icons--user" />
        {{ $t('_common:buttons.login') }}
    </v-btn>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'LoginButtonVariant1',
        inheritsAttribute: true,
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.buttons'
        },
        components: {
            BaseIcon
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-login-btn.v-btn {
        height: 42px;
        padding: 0 13px;
        background-color: theme-color( 'primary' );
        color: theme-color( 'white' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'bold' );
        border-radius: border-radius( 'm' ) !important;
        text-transform: none;
        min-width: auto;

        .gst-login-btn__icon {
            margin-right: 3px;
        }

        .gst-login-btn__icon ::v-deep .gst-svg-icon {
            fill: theme-color( "white" );
        }
    }
</style>
