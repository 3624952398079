import i18next from  'i18next';
import AuthenticationService from '@core/services/AuthenticationService';
import {
    getSessionId as sessionUtilsGetSessionId,
    hasSessionId as sessionUtilsHasSessionId
} from '@core/utils/sessionUtils';
import mutationTypes from '@core/helpers/storeFactories/helpers/mutationTypes';
import userLoyaltyWallet from '@core/store/modules/user/userLoyaltyWallet';
import userAnalytics from './userAnalytics';
import userBrowsingHistory from './userBrowsingHistory';
import userFavoriteEntities from './userFavoriteEntities';
import userLocation from './userLocation';
import userProfile from './userProfile';
import userACL from './userACL';

const STORE_NAME = 'user';
const hasSession = sessionUtilsHasSessionId( );

if ( !hasSession ) {
    sessionUtilsGetSessionId( );
}

const store = {
    name: STORE_NAME,
    namespaced: true,
    state: {
        firstAccess: !hasSession,
        email: null,
        expireAt: null,
        isAuth: false,
        /**
         * needsLogout Boolean
         *
         * During any call to BE if 403 is received this will be put to true
         * The user should be logout and should be send to sso login ( if we have ). We have a listener on on this on
         * appAuthMixin
         */
        needsLogout: false
    },
    modules: {
        analytics:              userAnalytics,
        browsingHistory:        userBrowsingHistory,
        favoriteEntities:       userFavoriteEntities,
        location:               userLocation,
        profile:                userProfile,
        wallet:                 userLoyaltyWallet,
        ACL:                    userACL
    },
    getters: {
        hasSession: () => {
            return hasSession;
        },
        firstAccess: state => {
            return state.firstAccess;
        },
        email: state => {
            return state.email;
        },
        isAuth: ( state ) => state.isAuth,
        isSessionExpireIn: ( state ) => ( expireIn = 0 ) => {
            const now = new Date( );
            const { expireAt } = state;

            return ( ( now.getTime( ) + expireIn ) > expireAt ) || !expireAt;
        },
        isAllowed: ( state ) => ( params ) => {
            return state.ACL.ACLList.some( ( item ) => item.entity === params.entity && item.action === params.action );
        }
    },
    mutations: {
        updateExpire( state ) {
            const expireTime = 30 * 24 * 60 * 60 * 1000; // 30 days
            const now = new Date( );

            state.expireAt = now.getTime( ) + expireTime;
        },
        setEmail( state, email ) {
            state.email = email;
        },
        setNeedsLogout( state ) {
            state.needsLogout = true;
        },
        setIsAuth( state, value ) {
            state.isAuth = value;
        },
        clear( state ) {
            state.firstAccess = null;
            state.email = null;
            state.expireAt = null;
            state.isAuth = false;
        },
    },
    actions: {
        async loginSSO( { dispatch, commit, getters }, { memberDelegateToken } ) {
            const walletData = await dispatch( 'user/wallet/createUserWalletByMemberDelegateToken', memberDelegateToken, { root: true } );
            if ( !walletData || !getters['wallet/isRegistered'] ) {
                dispatch( `${mutationTypes.CLEAR}` );
                dispatch(
                    'notification/error',
                    {
                        title: i18next.t( '_common:messages.loginInternalError.title' ),
                        content: i18next.t( '_common:messages.loginInternalError.message' )
                    },
                    { root: true } );
                return false;
            }
            commit( `user/analytics/${mutationTypes.CLEAR}`, null, { root: true } );
            dispatch( 'login' );

            return walletData;
        },
        /**
         * get session for user
         *
         * For SSO this call needs to be added after we get an sso token
         *
         * @param {*} param0
         * @returns
         */
        async getSession( { dispatch, getters } ) {
            const session = await dispatch( 'user/profile/getSession', getters.email, { root: true } );
            if ( !session ) {
                dispatch( `${mutationTypes.CLEAR}` );
                return false;
            }
            return session;
        },
        async login( { commit } ) {
            commit( 'updateExpire' );
        },
        async init( { dispatch, getters } ) {
            if ( getters.isAuth ) {
                let response = await dispatch( 'user/wallet/get', getters.email, { root: true } );
                if ( !response ) {
                    dispatch( `${mutationTypes.CLEAR}` );
                    return false;
                }
                return true;
            }
            return false;
        },
        async logout ( { dispatch }, refresh = false ) {
            if ( refresh ) {
                /**
                 * We need to wait for vuex persist to save the data
                 * in the local storage
                 */
                this.subscribe( ( ) => {
                    setTimeout( ( ) => {
                        window.location = '/';
                    }, 100 );
                }, { prepend: true } );
            }
            dispatch( mutationTypes.CLEAR );
            window.history.replaceState( null, '', window.location.pathname );
        },
        async invalidate( { commit } ) {
            commit ( 'setNeedsLogout' );
        },
        setEmail( { commit }, email ) {
            commit( 'setEmail', email );
        },
        [ mutationTypes.CLEAR ] ( { commit } ) {
            commit( 'clear' );
            commit( `user/profile/${mutationTypes.CLEAR}`, null, { root: true } );
            commit( `user/browsingHistory/${mutationTypes.CLEAR}`, null, { root: true } );
            commit( `user/wallet/${mutationTypes.CLEAR}`, null, { root: true } );
            commit( `user/analytics/${mutationTypes.CLEAR}`, null, { root: true } );
            commit( `appState/${mutationTypes.CLEAR}`, null, { root: true } );
            commit( `user/ACL/buildACLList`, null, { root: true } );
            AuthenticationService.clear( );
        }
    },
    _persistent: {
        getDataToSave: ( state ) => {
            const storeState = state[ STORE_NAME ];

            const ret =  {
                email: storeState.email,
                expireAt: storeState.expireAt,
                analytics: userAnalytics._persistent.getDataToSave( state[STORE_NAME] ),
                browsingHistory: userBrowsingHistory._persistent.getDataToSave( state[ STORE_NAME ] ),
                location: userLocation._persistent.getDataToSave( state[ STORE_NAME ] ),
                profile: userProfile._persistent.getDataToSave( state[STORE_NAME] ),
                wallet: userLoyaltyWallet._persistent.getDataToSave( state[STORE_NAME] ),
            };

            return ret;
        },
        getDataToLoad: ( data ) => {
            return {
                email: data.email,
                expireAt: data.expireAt,
                analytics: userAnalytics._persistent.getDataToLoad( data && data.analytics ? data.analytics : {} ),
                browsingHistory: userBrowsingHistory._persistent.getDataToLoad( data && data.browsingHistory ? data.browsingHistory : {} ),
                location: userLocation._persistent.getDataToLoad( data && data.location ? data.location : {} ),
                profile: userProfile._persistent.getDataToLoad( data && data.profile ? data.profile : {} ),
                wallet: userLoyaltyWallet._persistent.getDataToLoad( data && data.wallet ? data.wallet : {} ),
            };
        }
    }
};
export default store;
