<template>
    <div class="gst-league d-flex flex-column align-center">
        <div v-if="!loading" class="gst-league__content content-max-width d-flex flex-column align-center">
            <BaseBanner
                class="gst-league__banner mb-12 mt-4">
                <template v-slot:gst-banner-content>
                    <BaseImage
                        class="gst-league__banner-image"
                        :src="bannerSrc"
                        height="312">
                        <template v-slot:content>
                            <div class="gst-league__banner-text">
                                {{ bannerText }}
                            </div>
                        </template>
                    </BaseImage>
                </template>
            </BaseBanner>
            <div class="gst-league__leagues d-flex flex-wrap">
                <LeagueTeamCard
                    v-for="team in leagueTeams"
                    :key="team.tmAttractionId"
                    class="mb-4"
                    :team-id="team.tmAttractionId"
                    :name="team.name"
                    :logo-url="team.logoUrl" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import BaseBanner from '@core/shared/components/misc/BaseBanner.vue';
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';
    import LeagueTeamCard from '@tenants/fanatics/views/TheLeague/components/LeagueTeamCard.vue';

    import apiServiceLeague from '@tenants/fanatics/api/apiServiceLeague.js';

    export default {
        name: 'LeaguePage',
        components: {
            LeagueTeamCard,
            BaseBanner,
            BaseImage
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.leaguePage'
        },
        data() {
            return {
                league: {},
                loading: true,
            };
        },
        computed: {
            numberOfColumns() {
                if ( this.$vuetify.breakpoint.smAndDown ) {
                    return 1;
                }
                if ( !this.$vuetify.breakpoint.lgAndUp ) {
                    return 2;
                }
                return 3;
            },
            leagueTeams( ) {
                const teams = this.league.data.teams;
                if ( this.numberOfColumns === 1 ) {
                    return teams;
                }
                //reorder the array so we will have an alphabetical order on the columns. 
                //Build n chunks and then reorder in the final array  
                const chunk = ( array, limit ) => {
                    if ( array.length <= limit ) return [ array ];
                    const perChunk = Math.ceil( array.length / Math.ceil( array.length / limit ) );
                    return [ array.slice( 0, perChunk ) ].concat( chunk( array.slice( perChunk ), limit ) );
                };
                const sizeColumns = Math.ceil( teams.length / this.numberOfColumns ) ;
                const chunks = chunk( teams, sizeColumns );
                return chunks[ 0 ].reduce( ( acc, itemChunk, indexChunk ) => {
                    acc.push( itemChunk );

                    for( let indexCol = 1; indexCol < this.numberOfColumns ; indexCol++ ) {
                        const itemChunk = chunks[ indexCol ]?.[ indexChunk ];
                        if ( itemChunk ) {
                            acc.push( itemChunk );
                        }
                    }

                    return acc;
                }, [ ] );
            },
            bannerSrc() {
                return require( `@tenants/fanatics/assets/banners/leagues/${this.league.data.id}.png` );
            },
            bannerText() {
                return `${this.league.data.id} Tickets`;
            }
        },
        methods: {
            ...mapActions( {
                notificationError:      'notification/error'
            } )
        },
        async mounted() {
            this.league = await apiServiceLeague.one( this.$route.params.id );
            if ( this.league.success )
                this.loading = false;
            else {
                this.notificationError( this.$t( 'notifications.leagueNotAvailable' ) );
                this.$router.push( { name: 'home' } );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

.gst-league {
    .gst-league__content {
        width: 100%;

        .gst-league__banner {
            position: relative;
            width: 100%;

            .gst-league__banner-image {
                position: relative;
                border-radius: 10px;
            }

            .gst-league__banner-image::after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                background: linear-gradient( 180deg, rgba( 0, 0, 0, 0 ) 0%, #000000 100% );
                opacity: 0.8178;
                content: " ";
                border-radius: 0 0 10px 10px;
            }

            .gst-league__banner-text {
                position: absolute;
                left: 16px;
                bottom: 24px;
                line-height: 30px; /* 100% */
                color: #FFFFFF;
                font-size: 30px;
                font-weight: 700;
                letter-spacing: -0.882px;
                font-style: normal;
                z-index: 1;
            }
        }
    }

    .gst-league-card {
        width: 44%;

        @include mobile-only {
            width: 90%;
        }

        @include desktop-lg-only {
            width: 31%;
        }
    }
}
</style>
