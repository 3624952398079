<template>
    <div class="gst-user-menu">
        <v-menu
            v-model="menuOpened"
            :close-on-content-click="false"
            :rounded="'0'">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="!menuOpened"
                    ref="button"
                    text
                    outlined
                    depressed
                    :class="['gst-user-menu__activator', menuOpened && 'gst-user-menu__activator--active']"
                    v-on="on">
                    <div class="gst-user-menu__activator-content d-flex wrap align-center flex-row">
                        <div class="mr-1 d-flex shrink ">
                            <BaseIcon class="gst-user-menu__icon" symbol-id="icons--user" />
                        </div>
                        <div class="d-flex flex-column align-start shrink">
                            <VClamp
                                autoresize
                                :max-lines="1"
                                class="gst-user-menu__name">
                                {{ fullName }}
                            </VClamp>
                            <VClamp
                                v-if="showUserBalance"
                                autoresize
                                :max-lines="1"
                                :style="stylePoints"
                                class="gst-user-menu__points"
                                @clampchange="onPointsClampChangeDo">
                                {{ $t("_common:terms.bonusPointWithCount", { count: userBalance, formattedCount: pointsFormated } ) }}
                            </VClamp>
                        </div>
                    </div>
                </v-btn>
                <v-btn
                    v-else
                    text
                    small
                    class="text-capitalize gst-user-menu__close px-0 float-right ml-auto"
                    color="white">
                    <BaseIcon symbol-id="icons--close" />
                </v-btn>
            </template>
            <div class="gst-user-menu__banner">
                <div class="text-center">
                    <span v-if="fullName"
                        class="name">
                        {{ fullName }}
                        <p class="mb-0">
                            •
                        </p>
                    </span>
                    <div v-if="showUserBalance">
                        <h3 class="gst-user-menu__banner-points">
                            {{ $t("_common:terms.bonusPointWithCount", { count: userBalance, formattedCount: pointsFormated} ) }}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="gst-user-menu__list">
                <ul>
                    <li
                        class="gst-menu-list__item gst-menu-list__item--id py-2">
                        <div class="d-flex justify-center align-center">
                            <div class="gst-menu-list__title">
                                {{ userDetail.memberId }}
                                <p v-if="formattedMemberStatus" class="gst-list-item__member-status mb-0">
                                    {{ formattedMemberStatus }}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="gst-menu-list__item">
                        <router-link
                            class="py-3"
                            :to="{name:'userFavorites'}"
                            :target="userFavoritesTarget"
                            @click.native="menuOpened = false"
                            @keypress.enter="menuOpened = false">
                            <div class="gst-user-menu__link">
                                {{ $t( '_common:pages.myFavorites.menu') }}
                            </div>
                        </router-link>
                    </li>
                    <li class="gst-menu-list__item py-3 gst-user-menu__item-logout">
                        <div class="gst-user-menu__link u-mouse-pointer" @click="onClickLogout" @keypress.enter="onClickLogout">
                            {{ $t( '_common:terms.logout') }}
                        </div>
                    </li>
                </ul>
            </div>
        </v-menu>
    </div>
</template>
<script>
    import VClamp from 'vue-clamp';
    import UserMenuMixin from '@core/mixins/menus/UserMenuMixin';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'UserMobileMenu',
        components: {
            VClamp,
            BaseIcon,
        },
        mixins: [ UserMenuMixin ],
        data( ) {
            return {
                pointsClamped: false,
            };
        },
        computed: {
            userFavoritesTarget() {
                return this.$route.name === 'cartCheckout' ? '_blank' : '_self';
            },
            stylePoints( ) {
                if ( this.pointsClamped ) {
                    return {
                        height: 0,
                        width: 0
                    };
                }
                return { };
            }
        },
        watch: {
            menuOpened( val ) {
                if ( val ) {
                    this.$emit( 'expand' );
                } else {
                    this.$emit( 'collapse' );
                }
            }
        },
        methods: {
            onPointsClampChangeDo( value ) {
                this.pointsClamped = value;
            }
        },
        beforeDestroy( ) {
            this.$emit( 'collapse' );
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .v-menu__content {
        top: 59px !important;
        left: 0;
        height: calc( 100% - 59px );
        width: 100%;
        background-color: theme-color( 'white' );
        max-width: 100%;
        box-shadow: none;
        border-radius: border-radius( 'none' );
    }

    .gst-user-menu {
        position: relative;

        .v-btn.v-btn--outlined.v-btn--text.gst-user-menu__activator,
        .v-btn.v-btn--outlined.v-btn--text.gst-user-menu__activator:focus {
            height: 42px;
            width: auto;
            padding-right: theme-spacing( 2 );
            padding-left: theme-spacing( 2 );
            border: 1px solid theme-color( 'primary' );
            background-color: transparent;
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'bold' );
            border-radius: border-radius( 'm' ) !important;
            min-width: unset;
            text-transform: none;
            transition: border 0.3s ease;

            svg {
                transition: all 0.3s ease;
                margin-left: -3px;
            }

            .gst-user-menu__activator-content {
                white-space: initial;
                max-width: 170px;
            }

            .gst-user-menu__icon ::v-deep .gst-svg-icon {
                fill: theme-color( 'primary' );
            }

            .gst-user-menu__points {
                color: theme-color( 'primary' );
                text-transform: lowercase;
            }

            &:hover::before {
                background-color: transparent;
            }
        }

        .v-btn.v-btn--outlined.v-btn--text.gst-user-menu__activator--active {
            border: 1px solid theme-color( 'primary' );
            background: transparent;
            color: theme-color( 'white' );
            transition: border 0.3s ease, background-color 0.3s ease;

            &::before {
                background-color: theme-color( 'primary' );
                opacity: 1;
            }

            .gst-user-menu__icon ::v-deep .gst-svg-icon {
                fill: theme-color( 'white' );
            }

            .gst-user-menu__points {
                color: theme-color( 'white' );
            }
        }
    }

    .gst-user-menu__list {
        padding-top: theme-spacing( 0 );
        text-align: center;
        
        ul {
            padding-left: theme-spacing( 0 );

            li {
                list-style: none;
            }
        }
        
        li.gst-menu-list__item {
            display: flex;
            padding: 0 16px;
            justify-content: center;
            align-items: center;
            min-height: 64px;
        }

        .gst-menu-list__item::before {
            background-color: transparent;
        }

        .gst-menu-list__item--id {
            background-color: theme-color( 'primary-darken-1' );
            font-size: font-size( "l" );

            .gst-menu-list__title {
                color: theme-color( 'white' );
            }
        }
    }

    .gst-user-menu__close.v-btn {
        min-width: auto;
    }

    .gst-user-menu__link {
        display: inline-block;
        position: relative;
        margin: 0 auto;
        color: theme-color( 'quaternary' );
        font-size: font-size( 'xxxl' );

        &:hover {
            color: theme-color( 'primary' );

            svg {
                ::v-deep .gst-svg-icon {
                    fill: theme-color( 'primary' );
                }
            }
        }

        .gst-user-menu__link--white {
            color: theme-color( 'white' );
        }

        svg {
            ::v-deep .gst-svg-icon {
                fill: theme-color( 'quaternary' );
                transition: all 0.5s ease;
            }
        }
    }

    .gst-user-menu__banner {
        @include linear-gradient(to right, theme-color('home-banner-gradient-start') 0%, theme-color('home-banner-gradient-end') 100%);
        
        padding: theme-spacing( 9, 0 );
        margin: theme-spacing( 0 );
        color: theme-color( 'white' );

        .name {
            font-size: font-size( 'xxxl' );
        }

        .gst-user-menu__banner-points {
            text-transform: lowercase;
        }
    }
</style>
