<template>
    <BaseIcon class="gst-icon-search-favorite-data" symbol-id="icons--search_favorite_data" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconSearchData',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";

    .gst-icon-search-favorite-data .gst-svg-icon {
        fill: theme-color( 'primary' );
    }
</style>
