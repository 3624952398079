import apiServiceWallet from '@core/api/apiServiceUserLoyaltyWallet';
import { normalizeUserLoyaltyWallet } from '@core/data/response/normalizeUserLoyaltyWallet';
import loyaltyProgramConstants from '@core/utils/constants/loyaltyProgram';

const STORE_NAME = 'wallet';
import mutationTypes from '@core/helpers/storeFactories/helpers/mutationTypes';
import LogService from '@core/services/LogService';
import apiServiceUser from '@core/api/apiServiceUser';

const retryGetCreateUserWallet = async ( apiFn, payload ) => {
    let retries = 3;
    let response;
    const normalizeResponse = ( response ) => {
        if ( response.success ) {
            return {
                success: true,
                data: normalizeUserLoyaltyWallet( response.data )
            };
        }

        return response;
    };
    const isResponseForAnRegisterUser = ( response ) => response.success && response.data.isRegistered;
    const isRegistrationPending = ( response ) => response.success && response.data.memberDetailsStatus === loyaltyProgramConstants.MEMBER_DETAILS_STATUS.PENDING;

    while( retries > 0 ) {
        response = await apiFn( payload );
        const normResponse = normalizeResponse( response );

        if ( isRegistrationPending ( normResponse ) && !isResponseForAnRegisterUser ( normResponse ) ) {
            retries--;
        } else {
            retries = 0;
        }
    }

    return response;
};

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        loading: false,
        detail: null,
        referralId: null,
        registerLoyaltyProgramURL: null,
    },
    getters: {
        loading: state => state.loading,
        getDetail: state => state.detail,
        balance: state => state.detail ? state.detail.balance : 0,
        isRegistered: state => state.detail ? state.detail.isRegistered : false,
        hasError: state => state.error,
        memberId: state => state.detail ? state.detail.memberId : null,
        email: state => state.detail ? state.detail.email : null,
    },
    mutations: {
        setDetail( state, data ) {
            state.detail = data;
        },
        setError( state, isError ) {
            state.error = isError;
        },
        setLoading( state, loading ) {
            state.loading = loading;
        },
        setReferralId( state, referralId ) {
            state.referralId = referralId;
        },
        setRegisterLoyaltyProgramURL( state, registerLoyaltyProgramURL ) {
            state.registerLoyaltyProgramURL = registerLoyaltyProgramURL;
        },
        [mutationTypes.CLEAR]( state ) {
            state.error = false;
            state.detail = null;
            state.referralId = null;
            state.registerLoyaltyProgramURL = null;
        }
    },
    actions: {
        get: async ( { dispatch, commit, rootGetters }, email ) => {
            const isAuth = rootGetters[ 'user/isAuth' ];
            
            if ( isAuth ) {
                commit( 'setLoading', true );
                const api = await apiServiceUser( );
                const response = await api.user.get();
                const ret = await dispatch( 'handleResponseUserWallet', { email, response } );
                commit( 'setLoading', false );
                
                return ret;
            }
        },
        createUserWalletByEmail: async ( { dispatch }, email ) => {
            if( email === null ) return;

            const api = await apiServiceWallet( '' );
            const response = await retryGetCreateUserWallet( api.wallet.createUserWallet, { email } );
            const ret = await dispatch( 'handleResponseUserWallet', { email, response } );

            return ret;
        },
        createUserWalletByMemberDelegateToken: async ( { dispatch }, memberDelegateToken ) => {
            if( memberDelegateToken === null ) return;

            const api = await apiServiceWallet( '' );
            const response = await retryGetCreateUserWallet( api.wallet.createUserWallet, { memberDelegateToken: memberDelegateToken } );
            const ret = await dispatch( 'handleResponseUserWallet', { memberDelegateToken, response } );

            return ret;
        },
        // internal response handler for create and get user wallet
        handleResponseUserWallet: async ( { commit, state }, { email, memberDelegateToken, response } ) => {
            commit( 'setError', false );

            if ( response.success ) {
                const data = normalizeUserLoyaltyWallet( response.data );

                commit( 'setDetail', data );
                commit( 'setReferralId', data.referralId );
                commit( `user/setEmail`, data.email, { root: true } );

                return state.detail;
            } else {
                commit( 'setError', true );
                email && LogService.debug( `problems loading email ${ email }`  );
                memberDelegateToken && LogService.debug( `problems loading memberDelegateToken ${ memberDelegateToken }`  );
                return false;
            }
        },
        // create new user on the loyalty partner
        registerUserWallet: async ( { commit }, email ) => {
            if( email === null ) return;

            const api = await apiServiceWallet( '' );
            const response =  await api.wallet.registerUserWallet( { email: email } );

            if( response.success ){
                commit( 'setRegisterLoyaltyProgramURL', response.data.registerLoyaltyProgramURL );
                commit( 'setReferralId', response.data.referralId );
            }
            return response;
        },
        setError( { commit }, isError ) {
            commit( 'setError', isError );
        },
        setLoading( { commit }, loading = true ) {
            commit ( 'setLoading', loading );
        }
    },
    _persistent: {
        getDataToSave: ( state ) => {
            return {
                detail: state[ STORE_NAME ].detail,
                referralId: state[ STORE_NAME ].referralId,
            };
        },
        getDataToLoad: ( data ) => {
            return {
                detail: data.detail,
                referralId: data.referralId,
            };
        }
    }
};
