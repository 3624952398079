import { getSetting as getSettingStorageUtils, saveSetting as saveSettingStorageUtils } from '@core/utils/storageUtils';

export const LOCAL_STORAGE_KEY = 'notificationTerms';

export function setNotificationTermsVisibility( visible ) {
    const expireTime = 10 * 60 * 1000; // 10 minutes
    saveSettingStorageUtils( LOCAL_STORAGE_KEY, visible, expireTime );
}

export function getNotificationTermsVisibility( ) {
    const notificationTerms = getSettingStorageUtils( LOCAL_STORAGE_KEY );
    if ( notificationTerms?.value ) {
        return notificationTerms.value;
    } else {
        localStorage.removeItem( LOCAL_STORAGE_KEY );
        return null;
    }
}

