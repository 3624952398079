<template>
    <BaseIcon symbol-id="icons--info_bordered" class="gst-icon-info-bordered" />
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconInfoBordered',
        components: {
            BaseIcon
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";

    .gst-icon-info-bordered .gst-svg-icon {
        fill: theme-color( 'septenary' );
    }

    .gst-icon-info-bordered circle {
        stroke: theme-color( 'quinary' );
    }
</style>
