<template>
    <BaseIcon symbol-id="icons--checkboxes--round_checkbox_unchecked" class="gst-icon-unchecked" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconCheckboxRoundUnchecked',
        components: {
            BaseIcon
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";

    .gst-icon-unchecked .gst-svg-icon {
        stroke: theme-color( 'tertiary' );
    }
</style>